import React from 'react'

export const Promix175 = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1336"
    height="703"
    viewBox="0 0 1336 703"
    {...props}
  >
    <defs>
      <pattern
        id="pattern"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 155 141"
      >
        <image
          width="155"
          height="141"
          href="../promix-175/PA01.161.13E.7602_4.png"
        />
      </pattern>
      <pattern
        id="pattern-2"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 45 53"
      >
        <image
          width="45"
          height="53"
          href="../promix-175/PA01.161.13E.7602_14.png"
        />
      </pattern>
      <pattern
        id="pattern-3"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 40 36"
      >
        <image
          width="40"
          height="36"
          href="../promix-175/PA01.161.13E.7602_10.png"
        />
      </pattern>
      <pattern
        id="pattern-4"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 27 48"
      >
        <image
          width="27"
          height="48"
          href="../promix-175/PA01.161.13E.7602_9.png"
        />
      </pattern>
      <pattern
        id="pattern-5"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 14 16"
      >
        <image
          width="14"
          height="16"
          href="../promix-175/PA01.161.13E.7602_8.png"
        />
      </pattern>
      <pattern
        id="pattern-6"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 25 18"
      >
        <image
          width="25"
          height="18"
          href="../promix-175/PA01.161.13E.7602_7.png"
        />
      </pattern>
      <pattern
        id="pattern-7"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 14 16"
      >
        <image
          width="14"
          height="16"
          href="../promix-175/PA01.161.13E.7602_6.png"
        />
      </pattern>
      <pattern
        id="pattern-8"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 37 23"
      >
        <image
          width="37"
          height="23"
          href="../promix-175/PA01.161.13E.7602_5.png"
        />
      </pattern>
      <pattern
        id="pattern-9"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 93 119"
      >
        <image
          width="93"
          height="119"
          href="../promix-175/PA01.161.13E.7602_1.png"
        />
      </pattern>
      <pattern
        id="pattern-10"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 257 263"
      >
        <image
          width="257"
          height="263"
          href="../promix-175/PA01.161.13E.7602_12.png"
        />
      </pattern>
      <pattern
        id="pattern-11"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 125 145"
      >
        <image
          width="125"
          height="145"
          href="../promix-175/PA01.161.13E.7602_18.png"
        />
      </pattern>
    </defs>
    <g
      className="vista-g"
      id="Group_80"
      data-name="Group 80"
      transform="translate(-346 -212)"
    >
      <image
        id="PA01.161.13E.7602_15"
        width="389"
        height="508"
        transform="translate(836 407)"
        href="../promix-175/PA01.161.13E.7602_15.png"
      />
      <rect
        id="PA01.161.13E.7602_4"
        width="271"
        height="247"
        transform="translate(605 212)"
        fill="url(#pattern)"
      />
      <rect
        id="PA01.161.13E.7602_14"
        width="79"
        height="94"
        transform="translate(1271 775)"
        fill="url(#pattern-2)"
        data-url="https://shopirbal.com/pt/produto/roda-macica-250-com-freio/"
        data-name="Roda Maciça Ø250 com Freio"
      />
      <image
        id="PA01.161.13E.7602_11"
        width="145"
        height="185"
        transform="translate(1314 243)"
        href="../promix-175/PA01.161.13E.7602_11.png"
      />
      <rect
        id="PA01.161.13E.7602_10"
        width="69"
        height="63"
        transform="translate(1232 345)"
        fill="url(#pattern-3)"
      />
      <rect
        id="PA01.161.13E.7602_9"
        width="47"
        height="84"
        transform="translate(1198 241)"
        fill="url(#pattern-4)"
        data-url="https://shopirbal.com/pt/produto/correia-6pj-636-elastica/"
        data-name="Correia 6PJ 636 Elástica"
      />
      <rect
        id="PA01.161.13E.7602_8"
        width="26"
        height="29"
        transform="translate(934 398)"
        fill="url(#pattern-5)"
        data-url="https://shopirbal.com/pt/produto/interruptor-b1/"
        data-name="Interruptor B1"
      />
      <image
        id="PA01.161.13E.7602_13"
        width="42"
        height="28"
        transform="translate(1308 616)"
        href="../promix-175/PA01.161.13E.7602_13.png"
      />
      <rect
        id="PA01.161.13E.7602_7"
        width="44"
        height="32"
        transform="translate(1188 440)"
        fill="url(#pattern-6)"
        data-url="https://shopirbal.com/pt/produto/rolamento-6303-2rs/"
        data-name="Rolamento do Lado do Pinhão RL 6303 2RS"
      />
      <rect
        id="PA01.161.13E.7602_6"
        width="25"
        height="29"
        transform="translate(960 585)"
        fill="url(#pattern-7)"
        data-url="https://shopirbal.com/pt/produto/pinhao-carreto-b1-150-cavilha/"
        data-name="Pinhão/Carreto B1-150 + CAVILHA"
      />
      <rect
        id="PA01.161.13E.7602_5"
        width="65"
        height="40"
        transform="translate(740 540)"
        fill="url(#pattern-8)"
        data-url="https://shopirbal.com/pt/produto/rolamento-rl-6207-2rs/"
        data-name="Rolamento do Cubo do Balde RL 6207 2RS"
      />
      <image
        id="PA01.161.13E.7602_3"
        width="215"
        height="262"
        transform="translate(346 278)"
        href="../promix-175/PA01.161.13E.7602_3.png"
        data-url="https://shopirbal.com/pt/produto/cremalheira-de-ferro-fundido-170-175-190-200/"
        data-name="Cremalheira de Ferro Fundido 170-175-190-200"
      />
      <rect
        id="PA01.161.13E.7602_1"
        width="163"
        height="208"
        transform="translate(469 697)"
        fill="url(#pattern-9)"
      />
      <image
        id="PA01.161.13E.7602_2"
        width="31"
        height="31"
        transform="translate(659 629)"
        href="../promix-175/PA01.161.13E.7602_2.png"
      />
      <rect
        id="PA01.161.13E.7602_12"
        width="101"
        height="103"
        transform="translate(1457 600)"
        fill="url(#pattern-10)"
        data-url="https://shopirbal.com/pt/produto/motor-ele-700w/"
        data-name="Motor ELE 700W"
      />
      <image
        id="PA01.161.13E.7602_17"
        width="77"
        height="90"
        transform="translate(1605 510)"
        href="../promix-175/PA01.161.13E.7602_17.png"
        data-url="https://shopirbal.com/pt/produto/ventoinha-para-motor-700w/"
        data-name="Ventoinha para Motor 700W"
      />
      <rect
        id="PA01.161.13E.7602_18"
        width="48"
        height="57"
        transform="translate(1411 503)"
        fill="url(#pattern-11)"
        data-url="https://shopirbal.com/pt/produto/condensador-700wt/"
        data-name="Condensador 700W"
      />
    </g>
  </svg>
)
